import { unix } from "moment";
export function now() {
    return Date.now() / 1000;
}
// Take a daystamp like "20210201" and return unixtime
export function parseDate(s) {
    if (!s || s.length !== 8) {
        throw new Error("Cannot parse date from string: ".concat(JSON.stringify(s)));
    }
    var y = +s.slice(0, 4);
    var m = +s.slice(4, 6);
    var d = +s.slice(6, 8);
    return Date.UTC(y, m - 1, d, 12) / 1000;
}
/* Fixes the supplied unixtime to 00:00:00 on the same day (uses Moment)
 @param {Number} ut Unix time  */
export var daysnap = function (ut) {
    var d = unix(ut).utc();
    d.hours(0);
    d.minutes(0);
    d.seconds(0);
    d.milliseconds(0);
    return d.unix();
};
